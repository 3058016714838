import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  success(message: string) {
    return this.snackBar.open(message, undefined, {duration: 2000, panelClass: ['success-snackbar'] });
  }

}
