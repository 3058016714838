import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
  errors: string[]
  constructor(public api: ApiService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.errors = []
    // const token = "my-token-string-from-server";
    //
    // //Authentication by setting header with token value
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       'Authorization': token
    //     }
    //   });
    // }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     setHeaders: {
    //       'content-type': 'application/json'
    //     }
    //   });
    // }
    //
    // request = request.clone({
    //   headers: request.headers.set('Accept', 'application/json')
    // });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        return event;
      }))
      // catchError((error: HttpErrorResponse) => {
      //   //  let er = Object.values(error.error)
      //   // let ers = []
      //   //  // er.forEach((item: any) => {
      //   //  //   item.forEach((i: any) => {
      //   //  //     ers.push(i)
      //   //  //   })
      //   //  // })
      //   this.api.errors = ers
      //
      //    console.log(this.errors)
      //    return throwError(error);
      // }));
  }


}
