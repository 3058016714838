import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
// import {Keys} from "../common/keys";
import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {ApiService} from "./api.service";

@Injectable()


export class InterceptorService implements HttpInterceptor {

  constructor(private router: Router, private api: ApiService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler)
    : Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' )
    });
    return next.handle(authReq).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.api.logout()
          } else {

          }
        }
      }));
  }
}
