import {EventEmitter, Injectable} from '@angular/core';
import {ApiRoutes} from "../common/api-routes";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {error} from "protractor";
import {map} from "rxjs/operators";
import {Route, Router} from "@angular/router";
import {SnackBarService} from "./snack-bar.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  errors = []
  isUserAccess = false
  base_img_url = "https://dashboard.zeloenergia.com//storage/app/"

  baseUrl = "https://dashboard.zeloenergia.com/public/api/"
  onLogin: EventEmitter<any> = new EventEmitter<any>()
  public userRuolo: string
  token: string;


  constructor(private httpClient: HttpClient, private router: Router, private snackBar: SnackBarService) { }

  get(to: ApiRoutes, callback: any) {
    this.httpClient.get(this.baseUrl + to).subscribe((res: any) => {
      callback(res)
    })
  }

  post(to: ApiRoutes, data: any, callback: any) {
    var options = {
      //
    }
    this.httpClient.post(this.baseUrl + to, data, options).subscribe(res => {
      callback(res)
    })
  }

  login(to: ApiRoutes, data: any) {
    this.errors = []
    var options = {
      headers: {'Content-Type': 'application/json'}
    }

    return this.httpClient.post(this.baseUrl + to, data, options)
      .pipe(
        map((response: any) => {
          const token = response.access_token;
          if (token) {
            this.userRuolo = response.user.ruolo
            localStorage.setItem('ruolo', this.userRuolo)
            this.token = token;
            localStorage.setItem('token', this.token);
            this.onLogin.emit()
            this.isUserAccess = true
            return true;
          } else {
            return false;
          }
        })
      )
  }

  logout() {
    this.isUserAccess = false
    localStorage.removeItem('token')
    this.router.navigate(['/login'])
    this.snackBar.success("Logout effettuato con successo")
  }

  upload(to: ApiRoutes, tipo: string, m_cubi_kwh: string, autolettura: string, file?: File): Observable<any> {
    const formData = new FormData()

    errors: [] = [];

    formData.append("tipo", tipo)
    if(tipo == 'pdr') {
      formData.append("m_cubi", m_cubi_kwh)
    }
    else if(tipo == 'pod') {
      formData.append("kwh", m_cubi_kwh)
    }
    formData.append("autolettura", autolettura)

    if(file) {
      formData.append("file", file, file.name)
    }

    return this.httpClient.post(this.baseUrl + to, formData)

  }

  getById(to: ApiRoutes, id: string, callback: any) {
    this.httpClient.get(this.baseUrl + to + '/' + id, ).subscribe((res: any) => {
      callback(res)
    })
  }

}
