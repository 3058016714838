import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {InterceptorService} from "./services/interceptor.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {MAT_DATE_LOCALE} from "@angular/material/core";
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'autolettura',
    loadChildren: () => import('./pages/autolettura/autolettura.module').then(m => m.AutoletturaPageModule)
  },
  {
    path: 'contatore',
    loadChildren: () => import('./pages/contatore/contatore.module').then(m => m.ContatorePageModule)
  },
  {
    path: 'convenzioni',
    loadChildren: () => import('./pages/convenzioni/convenzioni.module').then(m => m.ConvenzioniPageModule)
  },
  {
    path: 'attivita' + '/:id',
    loadChildren: () => import('./pages/attivita/attivita.module').then( m => m.AttivitaPageModule)
  },
  {
    path: 'autolettura_luce',
    loadChildren: () => import('./pages/autolettura-luce/autolettura-luce.module').then( m => m.AutoletturaLucePageModule)
  },
  {
    path: 'pagamenti',
    loadChildren: () => import('./pages/pagamenti/pagamenti.module').then( m => m.PagamentiPageModule)
  },
  {
    path: 'contatti',
    loadChildren: () => import('./pages/contatti/contatti.module').then( m => m.ContattiPageModule)
  },
  {
    path: 'prodotti',
    loadChildren: () => import('./pages/prodotti/prodotti.module').then( m => m.ProdottiPageModule)
  },
  {
    path: 'prodotto' + '/:id',
    loadChildren: () => import('./pages/prodotto/prodotto.module').then( m => m.ProdottoPageModule)
  },
  {
    path: 'accesso',
    loadChildren: () => import('./pages/accesso/accesso.module').then( m => m.AccessoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registrazione',
    loadChildren: () => import('./pages/registrazione/registrazione.module').then(m => m.RegistrazionePageModule)
  },
  {
    path: 'consumi',
    loadChildren: () => import('./pages/consumi/consumi.module').then( m => m.ConsumiPageModule)
  },
  {
    path: 'bollette',
    loadChildren: () => import('./pages/bollette/bollette.module').then( m => m.BollettePageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }, { provide: MAT_DATE_LOCALE, useValue: 'it-IT' }
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    }*/
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
